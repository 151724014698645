import React       from 'react';
import { graphql } from 'gatsby';
import { Box }     from '@chakra-ui/react';

import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia  from '@interness/theme-default/src/components/HeaderMedia';
import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Lightbox     from '@interness/web-core/src/components/media/Lightbox/Lightbox';
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Center       from '@interness/web-core/src/components/structure/Center/Center';


const SchieferarbeitenPage = (props) => {
  return (
    <>
      <HeaderMedia id="header-schiefer"/>s
      <Spacer/>
      <Wrapper>
        <Heading subtitle="Tradition und Zukunft">Schieferarbeiten</Heading>
        <Box textAlign="justify">
          <p>
            Schiefer ein traditioneller Baustoff der den Wandel der Zeit mit Bravour bestanden hat. Ob an Dach und Wand
            mit
            den unterschiedlichsten Deckungen und Formaten bis hin zum Farbschiefer lassen sich herrliche Arbeiten an
            Gebäuden herstellen, die zeitlos sicher und wirtschaftlich sind. Durch ständige Überarbeitung und
            Weiterentwicklung, z. B. dachintegrierte Photovoltaik-Systeme, wirtschaftlichere und neue Deckungen ist und
            bleibt Schiefer ein traditionelles und langlebiges Material für Dach und Wand.
          </p>
        </Box>
        <Spacer/>
        <Heading tag="h2">Übersicht Schieferarbeiten</Heading>
        <Lightbox images={props.data.gallery.images} thumbnails={props.data.gallery.thumbnails} fromApi/>
        <Spacer/>
        <Center>
          <CallToAction/>
        </Center>
      </Wrapper>
      <Spacer/>
    </>
  )
};

export default SchieferarbeitenPage;

export const query = graphql`
    query {
        gallery: directusMediaCollection(name: {eq: "schieferarbeiten-gallery"}) {
            ...LightboxGalleryGrid
        }
    }
`;